<template>
  <pool title="职务" @chooseCount="chooseCount" :dataList="showDataList" :countList="countList" v-loading="loading"></pool>
</template>

<script>
import {
  loadDetail
} from './lib'

import pool from './pool'
export default {
  data () {
    return {
      dataList: [],
      searchParm: {
        roleId: null
      },
      roleDataList: [],
      loading: false
    }
  },
  components: {
    pool
  },
  computed: {
    showDataList () {
      if (this.searchParm.roleId) {
        return this.dataList.filter(v => {
          if (this.searchParm.roleId === -1) {
            return v.roleIds.length === 0
          } else {
            return v.roleIds.includes(String(this.searchParm.roleId))
          }
        })
      }
      return this.dataList
    },
    countList () {
      let dataMap = {}
      this.dataList.forEach(v => {
        if (v.roleIds && v.roleIds.length > 0) {
          v.roleIds.forEach(roleId => {
            dataMap[roleId] = (dataMap[roleId] || 0) + 1
          })
        } else {
          dataMap['-1'] = (dataMap['-1'] || 0) + 1
        }
      })
      let keys = Object.keys(dataMap)
      let data = this.roleDataList.filter(v => keys.includes(String(v.id))).map(v => {
        return {
          id: v.id,
          name: v.name,
          value: dataMap[v.id]
        }
      })
      if (dataMap['-1']) {
        data.push({
          id: -1,
          name: '无职务',
          value: dataMap['-1']
        })
      }
      data.forEach(v => {
        v.active = this.searchParm.roleId === v.id
      })
      return data
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    chooseCount (cIndex) {
      let roleId = this.countList[cIndex].id
      if (this.searchParm.roleId === roleId) {
        this.searchParm.roleId = null
      } else {
        this.searchParm.roleId = roleId
      }
    },
    async loadData () {
      this.loading = true
      let datas = await loadDetail(this)
      let roleDataList = []
      datas.forEach((v) => {
        v.userOrgRoleList.filter(v1 => v1.roleId).forEach(v1 => {
          if (!roleDataList.find(v2 => v2.id === v1.roleId)) {
            roleDataList.push({
              id: v1.roleId,
              name: v1.roleName
            })
          }
        })
      })
      this.roleDataList = roleDataList
      this.dataList = datas
      this.loading = false
    }
  }
}
</script>
